import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { createBrowserHistory } from 'history'

import actions from './actions';
import { LAMBDA_AUTH } from "../../config"
import Axios from 'axios';
import { notification } from 'antd';
const history = createBrowserHistory();



function* loginRequestToken() {
	yield takeEvery('LOGIN_REQUEST_TOKEN', function* ({ payload, callback }) {
		const { Token } = payload


		const response = yield call(callLoginWithToken, Token)

		if (response) {
			yield put({
				type: actions.LOGIN_SUCCESS_DATA,
				payload: response.data,
			})
		}
		callback && callback()
	});
}

function callLoginWithToken(Token) {
	return Axios.post(`${LAMBDA_AUTH}/loginWithToken`, {}, {
		headers: {
			Authorization: `Bearer ${Token}`
		}
	})
		.then(response => {
			const LastIdNacionalidad = Number(localStorage.getItem('LastIdNacionalidad'))
			const nacionalidad = response.data.payload.nacionalidades.find(nac => nac.Id === LastIdNacionalidad) || response.data.payload.nacionalidades[0]

			const LastIdUsuarioPerfil = Number(localStorage.getItem('LastIdUsuarioPerfil'))
			const usuarioPerfil = response.data.payload.usuarioPerfiles.find(up => up.Id === LastIdUsuarioPerfil) || response.data.payload.usuarioPerfiles[0]



			const privilegiosPerfil = response.data.payload.privilegiosPerfiles.find(p => p.IdPerfil === usuarioPerfil.perfil.IdPerfil)

			const cdnData = response.data.payload.centroNegociosPerfiles.find(cdn => cdn.IdPerfil === privilegiosPerfil.IdPerfil)

			const LastIdCdnPrefil = localStorage.getItem('LastCodigoCdnPerfil')
			let centroNegocioPerfil = null
			if (cdnData && cdnData.centrosNegocios.length > 0) {
				centroNegocioPerfil = cdnData.centrosNegocios.find(cdn => cdn.Codigo === LastIdCdnPrefil) || cdnData.centrosNegocios[0]

				localStorage.setItem('LastCodigoCdnPerfil', centroNegocioPerfil.Codigo)
			}
			localStorage.setItem('LastIdNacionalidad', nacionalidad.Id)
			localStorage.setItem('LastIdUsuarioPerfil', usuarioPerfil.Id)


			response.data.payload = {
				...response.data.payload,
				nacionalidad,
				usuarioPerfil,
				privilegiosPerfil,
				centroNegocioPerfil
			}
			response.data.Token = Token
			return response
		})
		.catch((err) => {
			notification.warning({ message: err.response.data.message })
			return null
		});
}


function* loginRequestData() {
	yield takeEvery('LOGIN_REQUEST_DATA', function* ({ payload, callback }) {
		const newAuth = payload

		document.title = newAuth.payload.usuarioPerfil.sucursal.Titulo

		yield put({
			type: actions.LOGIN_SUCCESS_DATA,
			payload,
		})
		callback && callback()
	})
}



function* logout() {
	yield takeEvery(actions.LOGOUT, function* ({ payload, callback }) {
		const { Token } = payload
		document.title = 'POS'

		yield call(callLogout, Token)


		yield put({
			type: actions.LOGOUT_SUCCESS,
			payload: payload
		})
	});
}


async function callLogout(Token) {
	Axios.post(`${LAMBDA_AUTH}/logout`, {

	}, {
		headers: {
			Authorization: `Bearer ${Token}`
		}
	})
		.then(console.log)
		.catch(console.error)
}






export default function* rootSaga() {
	yield all([
		fork(loginRequestToken),
		fork(loginRequestData),
		fork(logout),
	]);
}
