import {
  SET_AGREGAR_TARIFA,
  SET_BASE_CLIENTE,
  SET_BASE_RIDER,
  SET_COURIER_DEFAULT,
  SET_CUOTA_SERVICIO_AGIL,
  SET_DELETE_TARIFA,
  SET_INIT_TARIFA,
  SET_KM_HASTA,
  SET_METRO_EXTRA_CLIENTE,
  SET_METRO_EXTRA_RIDER,
  TYPE_TARIFA_DEFAULT,
  TYPE_TARIFA_STORE,
  TYPE_TARIFA_ZONE,
  SET_TARIFA_COMERCIO,
  SET_TARIFA_ZONA,
  SET_CAMBIO_TARIFAS,
  SET_TAB_TARIFAS,
  SET_DELETE_ALL_TARIFA,
  SET_INIT_TARIFA_COURIER,
  SET_AGREGAR_TARIFA_COURIER,
  SET_KM_HASTA_COURIER,
  SET_CUOTA_SERVICIO_COURIER,
  SET_DELETE_TARIFA_COURIER,
  SET_BASE_RETIRO,
  SET_GASTO_ENVIO_COURIER
} from "./types"

const INIT_STATE = {
  Default: [],
  Zonas: [],
  Comercios: [],
  ListCourier: [],
  ListZonas: [],
  isChangeTarifas: false,
  tabTarifas: TYPE_TARIFA_DEFAULT,
  IVA: 0
}

export default (state = INIT_STATE, action) => {
  switch (action?.type) {

    case SET_INIT_TARIFA: {
      state.Default = action?.payload?.Default
      state.Zonas = action?.payload?.Zonas
      state.Comercios = action?.payload?.Comercios
      state.ListCourier = action?.payload?.allCourier
      state.ListZonas = action?.payload?.allZonas
      state.IVA = (action?.payload?.IVA) ? parseInt(action?.payload?.IVA) : 0

      return {
        ...state,
      }
    }

    case SET_INIT_TARIFA_COURIER: {
      state.ListCourier = action?.payload?.allCourier
      state.IVA = (action?.payload?.IVA) ? parseInt(action?.payload?.IVA) : 0

      return {
        ...state,
      }
    }

    case SET_BASE_CLIENTE: {
      if (action?.payload?.type === TYPE_TARIFA_DEFAULT && state?.Default[action?.payload?.position]) {
        state.Default[action?.payload?.position].PrecioBaseCliente = action?.payload?.value
      }

      if (action?.payload?.type === TYPE_TARIFA_ZONE && state?.Zonas[action?.payload?.position]) {
        state.Zonas[action?.payload?.position].PrecioBaseCliente = action?.payload?.value
      }

      if (action?.payload?.type === TYPE_TARIFA_STORE) {
        const pos = state?.Comercios.findIndex((e) => e?.IdComercio === action?.payload?.store?.IdComercio)
        if (state.Comercios[pos] && state.Comercios[pos].Sucursales[action?.payload?.position]) {
          state.Comercios[pos].Sucursales[action?.payload?.position].PrecioBaseCliente = action?.payload?.value
        }
      }

      return {
        ...state
      }
    }

    case SET_BASE_RIDER: {
      if (action?.payload?.type === TYPE_TARIFA_DEFAULT && state?.Default[action?.payload?.position]) {
        state.Default[action?.payload?.position].PrecioBaseRider = action?.payload?.value
      }

      if (action?.payload?.type === TYPE_TARIFA_ZONE && state?.Zonas[action?.payload?.position]) {
        state.Zonas[action?.payload?.position].PrecioBaseRider = action?.payload?.value
      }

      if (action?.payload?.type === TYPE_TARIFA_STORE) {
        const pos = state?.Comercios.findIndex((e) => e?.IdComercio === action?.payload?.store?.IdComercio)
        if (state.Comercios[pos] && state.Comercios[pos].Sucursales[action?.payload?.position]) {
          state.Comercios[pos].Sucursales[action?.payload?.position].PrecioBaseRider = action?.payload?.value
        }
      }

      return {
        ...state
      }
    }

    case SET_BASE_RETIRO: {
      if (action?.payload?.type === TYPE_TARIFA_DEFAULT && state?.Default[action?.payload?.position]) {
        state.Default[action?.payload?.position].CuotaServicioRetiroNeto = action?.payload?.value
      }

      if (action?.payload?.type === TYPE_TARIFA_ZONE && state?.Zonas[action?.payload?.position]) {
        state.Zonas[action?.payload?.position].CuotaServicioRetiroNeto = action?.payload?.value
      }

      if (action?.payload?.type === TYPE_TARIFA_STORE) {
        const pos = state?.Comercios.findIndex((e) => e?.IdComercio === action?.payload?.store?.IdComercio)
        if (state.Comercios[pos] && state.Comercios[pos].Sucursales[action?.payload?.position]) {
          state.Comercios[pos].Sucursales[action?.payload?.position].CuotaServicioRetiroNeto = action?.payload?.value
        }
      }

      return {
        ...state
      }
    }

    case SET_KM_HASTA: {
      if (action?.payload?.type === TYPE_TARIFA_DEFAULT && state?.Default[action?.payload?.position]) {
        state.Default[action?.payload?.position].Tarifas[action?.payload?.posTarifa].Hasta = action?.payload?.value

        if (state.Default[action?.payload?.position].Tarifas[action?.payload?.posTarifa + 1]) {
          state.Default[action?.payload?.position].Tarifas[action?.payload?.posTarifa + 1].Desde = action?.payload?.value
          state.Default[action?.payload?.position].Tarifas[action?.payload?.posTarifa + 1].Hasta = action?.payload?.value + 1
        }
      }

      if (action?.payload?.type === TYPE_TARIFA_ZONE && state?.Zonas[action?.payload?.position]) {
        state.Zonas[action?.payload?.position].Tarifas[action?.payload?.posTarifa].Hasta = action?.payload?.value

        if (state.Zonas[action?.payload?.position].Tarifas[action?.payload?.posTarifa + 1]) {
          state.Zonas[action?.payload?.position].Tarifas[action?.payload?.posTarifa + 1].Desde = action?.payload?.value
          state.Zonas[action?.payload?.position].Tarifas[action?.payload?.posTarifa + 1].Hasta = action?.payload?.value + 1
        }
      }

      if (action?.payload?.type === TYPE_TARIFA_STORE) {
        const pos = state?.Comercios.findIndex((e) => e?.IdComercio === action?.payload?.store?.IdComercio)
        if (state.Comercios[pos] && state.Comercios[pos].Sucursales[action?.payload?.position]) {
          state.Comercios[pos].Sucursales[action?.payload?.position].Tarifas[action?.payload?.posTarifa].Hasta = action?.payload?.value

          if (state.Comercios[pos].Sucursales[action?.payload?.position].Tarifas[action?.payload?.posTarifa + 1]) {
            state.Comercios[pos].Sucursales[action?.payload?.position].Tarifas[action?.payload?.posTarifa + 1].Desde = action?.payload?.value
            state.Comercios[pos].Sucursales[action?.payload?.position].Tarifas[action?.payload?.posTarifa + 1].Hasta = action?.payload?.value + 1
          }
        }
      }

      return {
        ...state
      }
    }

    case SET_COURIER_DEFAULT: {
      if (action?.payload?.type === TYPE_TARIFA_DEFAULT && state?.Default[action?.payload?.position]) {
        state.Default[action?.payload?.position].Tarifas[action?.payload?.posTarifa].CourierDefault = action?.payload?.value
      }

      if (action?.payload?.type === TYPE_TARIFA_ZONE && state?.Zonas[action?.payload?.position]) {
        state.Zonas[action?.payload?.position].Tarifas[action?.payload?.posTarifa].CourierDefault = action?.payload?.value
      }

      if (action?.payload?.type === TYPE_TARIFA_STORE) {
        const pos = state?.Comercios.findIndex((e) => e?.IdComercio === action?.payload?.store?.IdComercio)
        if (state.Comercios[pos] && state.Comercios[pos].Sucursales[action?.payload?.position]) {
          state.Comercios[pos].Sucursales[action?.payload?.position].Tarifas[action?.payload?.posTarifa].CourierDefault = action?.payload?.value
        }
      }

      return {
        ...state
      }
    }

    case SET_METRO_EXTRA_CLIENTE: {
      if (action?.payload?.type === TYPE_TARIFA_DEFAULT && state?.Default[action?.payload?.position]) {
        state.Default[action?.payload?.position].Tarifas[action?.payload?.posTarifa].MetroExtraCliente = action?.payload?.value
      }

      if (action?.payload?.type === TYPE_TARIFA_ZONE && state?.Zonas[action?.payload?.position]) {
        state.Zonas[action?.payload?.position].Tarifas[action?.payload?.posTarifa].MetroExtraCliente = action?.payload?.value
      }

      if (action?.payload?.type === TYPE_TARIFA_STORE) {
        const pos = state?.Comercios.findIndex((e) => e?.IdComercio === action?.payload?.store?.IdComercio)
        if (state.Comercios[pos] && state.Comercios[pos].Sucursales[action?.payload?.position]) {
          state.Comercios[pos].Sucursales[action?.payload?.position].Tarifas[action?.payload?.posTarifa].MetroExtraCliente = action?.payload?.value
        }
      }

      return {
        ...state
      }
    }

    case SET_METRO_EXTRA_RIDER: {
      if (action?.payload?.type === TYPE_TARIFA_DEFAULT && state?.Default[action?.payload?.position]) {
        state.Default[action?.payload?.position].Tarifas[action?.payload?.posTarifa].MetroExtraRider = action?.payload?.value
      }

      if (action?.payload?.type === TYPE_TARIFA_ZONE && state?.Zonas[action?.payload?.position]) {
        state.Zonas[action?.payload?.position].Tarifas[action?.payload?.posTarifa].MetroExtraRider = action?.payload?.value
      }

      if (action?.payload?.type === TYPE_TARIFA_STORE) {
        const pos = state?.Comercios.findIndex((e) => e?.IdComercio === action?.payload?.store?.IdComercio)
        if (state.Comercios[pos] && state.Comercios[pos].Sucursales[action?.payload?.position]) {
          state.Comercios[pos].Sucursales[action?.payload?.position].Tarifas[action?.payload?.posTarifa].MetroExtraRider = action?.payload?.value
        }
      }

      return {
        ...state
      }
    }

    case SET_CUOTA_SERVICIO_AGIL: {
      if (action?.payload?.type === TYPE_TARIFA_DEFAULT && state?.Default[action?.payload?.position]) {
        state.Default[action?.payload?.position].Tarifas[action?.payload?.posTarifa].CuotaServicioDeliveryNeto = action?.payload?.value
      }

      if (action?.payload?.type === TYPE_TARIFA_ZONE && state?.Zonas[action?.payload?.position]) {
        state.Zonas[action?.payload?.position].Tarifas[action?.payload?.posTarifa].CuotaServicioDeliveryNeto = action?.payload?.value
      }

      if (action?.payload?.type === TYPE_TARIFA_STORE) {
        const pos = state?.Comercios.findIndex((e) => e?.IdComercio === action?.payload?.store?.IdComercio)
        if (state.Comercios[pos] && state.Comercios[pos].Sucursales[action?.payload?.position]) {
          state.Comercios[pos].Sucursales[action?.payload?.position].Tarifas[action?.payload?.posTarifa].CuotaServicioDeliveryNeto = action?.payload?.value
        }
      }

      return {
        ...state
      }
    }

    case SET_AGREGAR_TARIFA: {
      if (action?.payload?.type === TYPE_TARIFA_DEFAULT && state.Default[action?.payload?.position]) {
        const tarifas = state?.Default[action?.payload?.position]?.Tarifas

        state.Default[action?.payload?.position].Tarifas.push({
          Desde: (tarifas[tarifas.length - 1]?.Hasta) ? tarifas[tarifas.length - 1]?.Hasta : 0,
          Hasta: (tarifas[tarifas.length - 1]?.Hasta) ? (tarifas[tarifas.length - 1]?.Hasta + 1) : 1,
          CourierDefault: null,
          MetroExtraCliente: 0,
          MetroExtraRider: 0,
          CuotaServicioDeliveryNeto: 0,
        })
      }

      if (action?.payload?.type === TYPE_TARIFA_ZONE && state.Zonas[action?.payload?.position]) {
        const tarifas = state?.Zonas[action?.payload?.position]?.Tarifas

        state.Zonas[action?.payload?.position].Tarifas.push({
          Desde: (tarifas[tarifas.length - 1]?.Hasta) ? tarifas[tarifas.length - 1]?.Hasta : 0,
          Hasta: (tarifas[tarifas.length - 1]?.Hasta) ? (tarifas[tarifas.length - 1]?.Hasta + 1) : 1,
          CourierDefault: null,
          MetroExtraCliente: 0,
          MetroExtraRider: 0,
          CuotaServicioDeliveryNeto: 0,
        })
      }

      if (action?.payload?.type === TYPE_TARIFA_STORE) {
        const pos = state?.Comercios.findIndex((e) => e?.IdComercio === action?.payload?.store?.IdComercio)

        if (state.Comercios[pos] && state.Comercios[pos].Sucursales[action?.payload?.position]) {
          const tarifas = state.Comercios[pos].Sucursales[action?.payload?.position].Tarifas

          state.Comercios[pos].Sucursales[action?.payload?.position].Tarifas.push({
            Desde: (tarifas[tarifas.length - 1]?.Hasta) ? tarifas[tarifas.length - 1]?.Hasta : 0,
            Hasta: (tarifas[tarifas.length - 1]?.Hasta) ? (tarifas[tarifas.length - 1]?.Hasta + 1) : 1,
            CourierDefault: null,
            MetroExtraCliente: 0,
            MetroExtraRider: 0,
            CuotaServicioDeliveryNeto: 0,
          })

        }
      }

      return {
        ...state
      }
    }

    case SET_DELETE_TARIFA: {
      if (action?.payload?.type === TYPE_TARIFA_DEFAULT && state.Default[action?.payload?.position]) {
        state.Default[action?.payload?.position].Tarifas.pop()
      }

      if (action?.payload?.type === TYPE_TARIFA_ZONE && state.Zonas[action?.payload?.position]) {
        state.Zonas[action?.payload?.position].Tarifas.pop()
      }

      if (action?.payload?.type === TYPE_TARIFA_STORE) {
        const pos = state?.Comercios.findIndex((e) => e?.IdComercio === action?.payload?.store?.IdComercio)
        if (state.Comercios[pos] && state.Comercios[pos].Sucursales[action?.payload?.position]) {
          state.Comercios[pos] && state.Comercios[pos].Sucursales[action?.payload?.position].Tarifas.pop()
        }
      }

      return {
        ...state
      }
    }

    case SET_TARIFA_COMERCIO: {
      const pos = state.Comercios.findIndex((e) => e?.IdComercio === action?.payload?.comercio?.IdComercio)

      if (state.Comercios[pos]) {
        action.payload.sucursales.map((e) => {
          const posSuc = state.Comercios[pos].Sucursales.findIndex((x) => x?.IdSucursal === e?.IdSucursal)
   
          if (!state.Comercios[pos].Sucursales[posSuc]) {
            state.Comercios[pos].Sucursales.push({
              IdSucursal: e?.IdSucursal,
              Nombre: e?.Titulo,
              CodigoTarifa: null,
              CodigoCentroNegocio: action?.payload?.CDN,
              Default: false,
              PrecioBaseCliente: 0,
              PrecioBaseRider: 0,
              CuotaServicioRetiroNeto: 0,
              Tarifas: [{
                Desde: 0,
                Hasta: 3,
                CourierDefault: null,
                MetroExtraCliente: 0,
                MetroExtraRider: 0,
                CuotaServicioDeliveryNeto: 0,
              }]
            })
          }
        })
      } else {
        const listSuc = action.payload.sucursales.map((e) => {
          return {
            IdSucursal: e?.IdSucursal,
            Nombre: e?.Titulo,
            CodigoTarifa: null,
            CodigoCentroNegocio: action?.payload?.CDN,
            PrecioBaseCliente: 0,
            PrecioBaseRider: 0,
            CuotaServicioRetiroNeto: 0,
            Default: false,
            Tarifas: [{
              Desde: 0,
              Hasta: 3,
              CourierDefault: null,
              MetroExtraCliente: 0,
              MetroExtraRider: 0,
              CuotaServicioDeliveryNeto: 0,
            }]
          }
        })

        state.Comercios.push({
          IdComercio: action?.payload?.comercio?.IdComercio,
          Nombre: action?.payload?.comercio?.Nombre,
          Sucursales: listSuc
        })
      }

      return state
    }

    case SET_TARIFA_ZONA: {
      action.payload.zonas.map((e) => {
        const pos = state.Zonas.findIndex((x) => x?.IdZona === e?.Id)

        if (!state.Zonas[pos]) {
          state.Zonas.push({
            IdZona: e?.Id,
            Zona: e?.Nombre,
            CodigoCentroNegocio: action?.payload?.CDN,
            CodigoTarifa: null,
            Default: false,
            PrecioBaseCliente: 0,
            PrecioBaseRider: 0,
            CuotaServicioRetiroNeto: 0,
            Tarifas: [{
              Desde: 0,
              Hasta: 3,
              CourierDefault: null,
              MetroExtraCliente: 0,
              MetroExtraRider: 0,
              CuotaServicioDeliveryNeto: 0,
            }]
          })
        }
      })

      return state
    }

    case SET_CAMBIO_TARIFAS: {
      state.isChangeTarifas = !state.isChangeTarifas

      return state
    }

    case SET_TAB_TARIFAS: {
      state.tabTarifas = action?.payload


      return state
    }

    case SET_DELETE_ALL_TARIFA: {
      if (action?.payload?.type === TYPE_TARIFA_ZONE && state.Zonas[action?.payload?.position]) {
        state.Zonas[action?.payload?.position].Tarifas = []
      }

      if (action?.payload?.type === TYPE_TARIFA_STORE) {
        const pos = state?.Comercios.findIndex((e) => e?.IdComercio === action?.payload?.store?.IdComercio)
        if (state.Comercios[pos] && state.Comercios[pos].Sucursales[action?.payload?.position]) {
          state.Comercios[pos].Sucursales[action?.payload?.position].Tarifas = []
        }
      }

      return {
        ...state
      }
    }

    case SET_AGREGAR_TARIFA_COURIER: {
      const pos = state.ListCourier.findIndex((e) => e?.IdCourier === action?.payload?.IdCourier)

      if (state.ListCourier[pos]) {
        const tarifas = state.ListCourier[pos]?.Tarifas

        state.ListCourier[pos].Tarifas.push({
          Desde: (tarifas[tarifas.length - 1]?.Hasta) ? tarifas[tarifas.length - 1]?.Hasta : 0,
          Hasta: (tarifas[tarifas.length - 1]?.Hasta) ? (tarifas[tarifas.length - 1]?.Hasta + 1) : 1,
          CuotaServicioNeto: 0,
        })
      }

      return {
        ...state
      }
    }

    case SET_KM_HASTA_COURIER: {
      const pos = state.ListCourier.findIndex((e) => e?.IdCourier === action?.payload?.IdCourier)

      if (state.ListCourier[pos]) {
        state.ListCourier[pos].Tarifas[action?.payload?.position].Hasta = action?.payload?.value


        if (state.ListCourier[pos].Tarifas[action?.payload?.position + 1]) {
          state.ListCourier[pos].Tarifas[action?.payload?.position + 1].Desde = action?.payload?.value
          state.ListCourier[pos].Tarifas[action?.payload?.position + 1].Hasta = action?.payload?.value + 1
        }
      }

      return {
        ...state
      }
    }

    case SET_GASTO_ENVIO_COURIER: {
      const pos = state.ListCourier.findIndex((e) => e?.IdCourier === action?.payload?.IdCourier)

      if (state.ListCourier[pos]) {
        state.ListCourier[pos].Tarifas[action?.payload?.position].GastoEnvioNeto = action?.payload?.value
      }

      return {
        ...state
      }
    }

    case SET_CUOTA_SERVICIO_COURIER: {
      const pos = state.ListCourier.findIndex((e) => e?.IdCourier === action?.payload?.IdCourier)

      if (state.ListCourier[pos]) {
        state.ListCourier[pos].Tarifas[action?.payload?.position].CuotaServicioNeto = action?.payload?.value
      }

      return {
        ...state
      }
    }

    case SET_DELETE_TARIFA_COURIER: {
      const pos = state.ListCourier.findIndex((e) => e?.IdCourier === action?.payload?.IdCourier)

      if (state.ListCourier[pos]) {
        state.ListCourier[pos].Tarifas.pop()
      }

      return {
        ...state
      }
    }

    default:
      return state
  }
}