import { isVoid } from "../../helpers/utils"
import {
    SET_MENUS_DE_AYUDA,
    ADD_MENUS_DE_AYUDA,
    ADD_MENU_DE_AYUDA_NAV_HISTORY,
    CLEAR_NAV_HISTORY,
    SAVE_MENU_DE_AYUDA,
    POP_MENU_DE_AYUDA_NAV_HISTORY,
    DELETE_MENU_DE_AYUDA,
    CHANGE_ACTIVE_MENU,
    SET_FATHER_MENU_DE_AYUDA,
    SET_ETIQUETAS
} from "./types"
/** @typedef {import("@iso/lib/menu_ayuda.lib").IEtiqueta} IEtiqueta */


/**
 * 
 * @typedef {
 *     IdUsuario: number,
 *     Nombre: string,
 *     Apellido: string,
 *     Imagen: string
 * } UsuarioCreador
 * 
 * @typedef {
 *     Id: number,
 *     IdMenuAyuda: number,
 *     Activo: boolean,
 *     tipoRequerimiento: {
 *         Id: number,
 *         Nombre: string,
 *         Codigo: string,
 *         Descripcion: string,
 *     }
 * } OpcionMenuAyudaRequerimiento
 * 
 * @typedef {
 *     Id: number,
 *     IdMenuAyuda: number,
 *     Activo: boolean,
 *     tipoSolucion: {
 *         Id: number,
 *         Nombre: string,
 *         Codigo: string,
 *         Descripcion: string
 *     }
 * } OpcionMenuAyudaSolucion
 * 
 * @typedef {
 *     Id: number,
 *     Titulo: string,
 *     Descripcion: string,
 *     Tipo: string,
 *     Activo: true,
 *     SolucionAutomatica: boolean,
 *     requerimiento: Array<OpcionMenuAyudaRequerimiento>,
 *     solucion: Array<OpcionMenuAyudaSolucion>
 * } OpcionMenuAyuda
 * 
 * @typedef {
 *     Id: number,
 *     TipoApp: string,
 *     Titulo: number,
 *     Descripcion: string,
 *     Tipo: string,
 *     Activo: boolean,
 *     createdAt: string,
 *     usuario: UsuarioCreador
 *     opciones: Array<OpcionMenuAyuda>
 * } MenuAyuda
 */

/**
 * @type {{
 *    navHistory: Array<number>,
 *    menusDeAyuda: Array<MenuAyuda>,
 *    etiquetas: Array<IEtiqueta>
 * }}
 */
const INIT_STATE = {
    navHistory: [],
    menusDeAyuda: [],
    etiquetas: []
}

export default (state = INIT_STATE, action) => {
    const [posFather, posChild] = state.navHistory
    switch (action.type) {
        case SET_MENUS_DE_AYUDA: {
            return {
                ...state,
                menusDeAyuda: action.payload
            }
        }
        case SET_ETIQUETAS: {
            return {
                ...state,
                etiquetas: action.payload
            }
        }
        case SET_FATHER_MENU_DE_AYUDA:
            if (!isVoid(posFather)) {
                state.menusDeAyuda[posFather] = action.payload
            } else {
                state.menusDeAyuda.push(action.payload)
                state.navHistory.push(state.menusDeAyuda.length - 1)
            }
            return {
                ...state
            }
        case ADD_MENUS_DE_AYUDA:
            state.menusDeAyuda.push(action.payload)
            return {
                ...state
            }

        case ADD_MENU_DE_AYUDA_NAV_HISTORY:
            if (state.navHistory.length < 2) state.navHistory.push(action.payload)
            return {
                ...state
            }

        case SAVE_MENU_DE_AYUDA:
            if (!isVoid(posChild)) {
                state.menusDeAyuda[posFather].Opciones[posChild] = action.payload
            } else if (!isVoid(posFather)) {
                state.menusDeAyuda[posFather] = action.payload
            } else {
                state.menusDeAyuda.push(action.payload)
                state.navHistory.push(state.menusDeAyuda.length - 1)
            }
            return {
                ...state
            }
        case POP_MENU_DE_AYUDA_NAV_HISTORY:
            state.navHistory.pop()
            return {
                ...state
            }
        case CLEAR_NAV_HISTORY:
            return {
                ...state,
                navHistory: []
            }
        case DELETE_MENU_DE_AYUDA:
            if (!isVoid(posChild)) {
                state.menusDeAyuda[posFather].Opciones.splice(posChild, 1)
            } else if (!isVoid(posFather)) {
                state.menusDeAyuda.splice(posFather, 1)
            }
            return {
                ...state
            }
        case CHANGE_ACTIVE_MENU:
            if (state.menusDeAyuda[action.payload.position]) {
                state.menusDeAyuda[action.payload.position].Activo = action.payload.active
            }
            return {
                ...state
            }
        default:
            return state
    }
}