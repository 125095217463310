
import actions from './actions';

const orders = [];

const initState = {
  orders,
  seectedId: 0,
};

export default function Orders(state = initState, action) {
  switch (action.type) {
    case actions.ORDERS_SUCCESS:
        return {
            orders: action.data,
            seectedId: action.data[0].IdPedido,
        };
    case actions.TIME_SUCCESS:      
      var todos = state.orders;
      var obj =  todos.find(el => el.IdPedido === action.IdPedido);

      for(var i=0; i<todos.length;i++){
        if(obj.IdPedido===todos[i].IdPedido){
          todos[i].Tiempo = action.data
        }
      }
      return {
        ...state,
        tiempo: action.data,
        IdPedido: action.IdPedido
      };
    case actions.TIME_ERROR:
      return {
        ...state,
        errorOrder: action.error
      };
    case actions.STATE_SUCCESS:   
      var newOrders = state.orders.filter(e => e.IdPedido !== action.IdPedido)
      return{
        orders: newOrders,
        seectedId: newOrders.length > 0 ? newOrders[0].IdPedido : 0
      };
    case actions.STATE_ERROR:
      return {
        ...state,
        errorOrder: action.error
      };  
    default:
      return state;
  }
}

export { orders };
