export const TYPE_TARIFA_DEFAULT = 'DEFAULT'
export const TYPE_TARIFA_ZONE = 'ZONE'
export const TYPE_TARIFA_STORE = 'STORE'

export const SET_INIT_TARIFA = 'SET_INIT_TARIFA'
export const SET_BASE_CLIENTE = 'SET_BASE_CLIENTE'
export const SET_BASE_RIDER = 'SET_BASE_RIDER'
export const SET_BASE_RETIRO = 'SET_BASE_RETIRO'
export const SET_KM_HASTA = 'SET_KM_HASTA'
export const SET_COURIER_DEFAULT = 'SET_COURIER_DEFAULT'
export const SET_METRO_EXTRA_CLIENTE = 'SET_METRO_EXTRA_CLIENTE'
export const SET_METRO_EXTRA_RIDER = 'SET_METRO_EXTRA_RIDER'
export const SET_CUOTA_SERVICIO_AGIL = 'SET_CUOTA_SERVICIO_AGIL'
export const SET_AGREGAR_TARIFA = 'SET_AGREGAR_TARIFA'
export const SET_DELETE_TARIFA = 'SET_DELETE_TARIFA'
export const SET_TARIFA_COMERCIO = 'SET_TARIFA_COMERCIO'
export const SET_TARIFA_ZONA = 'SET_TARIFA_ZONA'
export const SET_CAMBIO_TARIFAS = 'SET_CAMBIO_TARIFAS'
export const SET_TAB_TARIFAS = 'SET_TAB_TARIFAS'
export const SET_DELETE_ALL_TARIFA = 'SET_DELETE_ALL_TARIFA'
export const SET_INIT_TARIFA_COURIER = 'SET_INIT_TARIFA_COURIER'
export const SET_AGREGAR_TARIFA_COURIER = 'SET_AGREGAR_TARIFA_COURIER'
export const SET_KM_HASTA_COURIER = 'SET_KM_HASTA_COURIER'
export const SET_GASTO_ENVIO_COURIER = 'SET_GASTO_ENVIO_COURIER'
export const SET_CUOTA_SERVICIO_COURIER = 'SET_CUOTA_SERVICIO_COURIER'
export const SET_DELETE_TARIFA_COURIER = 'SET_DELETE_TARIFA_COURIER'