module.exports = {
    backendUrl: "https://super.server.getagil.com/super-admin-backend/",
    LAMBDA_SUPER: 'https://lambda.getagil.com/prod-super',
    LAMBDA_COMMON: 'https://lambda.getagil.com/prod-common',
    LAMBDA_AUTH: 'https://lambda.getagil.com/prod-auth',
    LAMBDA_POS: 'https://lambda.getagil.com/prod-pos',
    LAMBDA_ADMIN: 'https://lambda.getagil.com/prod-admin',
    LAMBDA_SHOP: "https://lambda.getagil.com/prod-shop",
    LAMBDA_RIDER: "https://lambda.getagil.com/prod-rider",
    LAMBDA_AGIL: "https://lambda.getagil.com/prod-agil",
    AppKey: 'bb4d49b255f3894a9bde271ffc0f20fb',
    backendUrlGraphql: "https://super.server.getagil.com/super-admin-backend/graphql",
    socketUrl: "https://socket.server.getagil.com/",
    AUTH_URL: "https://account.getagil.com",
    SOCKET_SHOP: `https://shop.server.getagil.com/`,
    MAPBOX_TOKEN: 'pk.eyJ1IjoiYWdpbG1hcCIsImEiOiJjbG1rbzY2amkwM3NoMmttb3dtMHRteTRkIn0.6gAtqofNy3MsffYN6ml4SQ',
    API_KEY_GOOGLE_MAPS: "AIzaSyCETkB78xH2peosxbPxCW7fiHxg0jxAXoM",
    RAPPI_CLIENT_ID: "HHk8Y6K55OuuYYoKJbmUaztXEcRrGM88",
    RAPPI_CLIENT_SECRET: "iuxDMSXUWpNM8EgKuhj5l6eUWgySN85KGWefD3GmNJsx6kJSZ7ij4-_zHvr9Jakc",
    RAPPI_AUDIENCE: "https://services.rappi.cl/api/v2/restaurants-integrations-public-api",
    UBER_CLIENT_ID: "ao0HHbd4oVOpIKUrtugy464TSED4ndLU",
    UBER_CLIENT_SECRET: "wfDyja0-dZNmI0lJavr3qMu-Dr4cAav-kjRc169y",
    TINTY_MCE: "owiocz2hm60g3svswoaqir51ifhobqcd41iycbl1h4osao2a"
}
