import actions from './actions'

export default function Courier(state = { dataCourier: [], listCourier: [] }, action) {
  switch (action.type) {
    case actions.ADD_DATA_COURIER:

      // APLICA SOLO PARA SETEAR CREDENCIALES
      if (action.payload.data.credentials !== undefined && action.payload.data.credentials !== null) {

        const key = Object.keys(action.payload.data.credentials)[0]
        const value = action.payload.data.credentials[key]

        if (state.dataCourier[action.payload.data.position].credentials !== undefined && state.dataCourier[action.payload.data.position].credentials !== null) {
          state.dataCourier[action.payload.data.position].credentials = state.dataCourier[action.payload.data.position].credentials.filter(e => Object.keys(e)[0] !== key)
          state.dataCourier[action.payload.data.position].credentials.push({ [key]: value })
        } else {
          state.dataCourier[action.payload.data.position] = { ...state.dataCourier[action.payload.data.position], credentials: [] }
          state.dataCourier[action.payload.data.position].credentials.push({ [key]: value })
        }

        return state
      }

      // APLICA SOLO PARA SETEAR SUCURSALES
      if (action.payload.data.sucursales !== undefined && action.payload.data.sucursales !== null) {
        if (state.dataCourier[action.payload.data.position].sucursales !== undefined && state.dataCourier[action.payload.data.position].sucursales !== null) {

          let exists = state.dataCourier[action.payload.data.position].sucursales.filter(e => e === action.payload.data.sucursales)

          if (exists !== null && exists.length > 0) {
            state.dataCourier[action.payload.data.position].sucursales = state.dataCourier[action.payload.data.position].sucursales.filter(e => e !== action.payload.data.sucursales)
          } else {
            state.dataCourier[action.payload.data.position].sucursales.push(action.payload.data.sucursales)
          }

        } else {
          state.dataCourier[action.payload.data.position] = { ...state.dataCourier[action.payload.data.position], sucursales: [] }
          state.dataCourier[action.payload.data.position].sucursales.push(action.payload.data.sucursales)
        }

        return state
      }

      // SOLO PUEDE QUEDAR UN COURIER COMO DEFAULT
      if (action.payload.data.defaultCourier !== undefined && action.payload.data.defaultCourier !== null) {
        state.dataCourier.map((e, i) => {
          if (e.defaultCourier !== undefined) {
            e.defaultCourier = false
          }
        })

        if (state.dataCourier[action.payload.data.position].defaultCourier !== undefined) {
          state.dataCourier[action.payload.data.position].defaultCourier = action.payload.data.defaultCourier
        } else {
          state.dataCourier[action.payload.data.position] = { ...state.dataCourier[action.payload.data.position], defaultCourier: action.payload.data.defaultCourier }
        }

        return state
      }

      // APLICA PARA SETEAR TODOS LOS DEMAS DATOS
      state.dataCourier[action.payload.data.position] = { ...state.dataCourier[action.payload.data.position], ...action.payload.data }

      return state

    case actions.ADD_LIST_COURIER:
      state.listCourier = action.payload.data
      return state

    case actions.CLEAN_DATA_COURIER:
      state.dataCourier = []
      return state

    default:
      return state
  }
}