import { all, takeEvery, fork, put, call } from "redux-saga/effects"
import actions from "./actions"
import { backendUrl } from "../../config"

export function* getOrders() {
    yield takeEvery("GET_ORDERS", function* ({ payload }) {
        const { IdSucursal, IdComercio, IdUsuario, Token } = payload
        if (IdSucursal && IdComercio && IdUsuario && Token) {
            const response = yield call(
                getData,
                IdSucursal,
                IdComercio,
                IdUsuario,
                Token
            )

            yield put({
                type: actions.ORDERS_SUCCESS,
                data: response.ok ? response.data : []
            })
        } else {
            yield put({ type: actions.ORDERS_ERROR, error: "Faltan datos" })
        }
    })
}

export function* getOrdersActive() {
    yield takeEvery("GET_ORDERS_ACTIVE", function* ({ payload }) {
        const { IdSucursal, IdComercio, IdUsuario, Token } = payload
        if (IdSucursal && IdComercio && IdUsuario && Token) {
            const response = yield call(
                getDataActive,
                IdSucursal,
                IdComercio,
                IdUsuario,
                Token
            )

            yield put({
                type: actions.ORDERS_SUCCESS,
                data: response.ok ? response.data : []
            })
        } else {
            yield put({ type: actions.ORDERS_ERROR, error: "Faltan datos" })
        }
    })
}

function getData(IdSucursal, IdComercio, IdUsuario, Token) {
    return new Promise(async function (resolve, reject) {
        var formBody = [
            encodeURIComponent("IdUsuario") +
                "=" +
                encodeURIComponent(IdUsuario) +
                "&" +
                encodeURIComponent("IdComercio") +
                "=" +
                encodeURIComponent(IdComercio) +
                "&" +
                encodeURIComponent("IdSucursal") +
                "=" +
                encodeURIComponent(IdSucursal)
        ]

        await fetch(backendUrl + "masterGetOrders", {
            method: "POST",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded;charset=UTF-8",
                Authorization: "Bearer " + Token
            },
            body: formBody
        })
            .then((response) => {
                const statusCode = response.status
                const data = response.json()
                return Promise.all([statusCode, data]).then((res) => ({
                    statusCode: res[0],
                    data: res[1]
                }))
            })
            .then((res) => {
                const { statusCode, data } = res

                if (statusCode === 200) {
                    resolve({
                        ok: true,
                        data: data.data
                    })
                } else {
                    resolve({ ok: false, error: data.error })
                }
            })
            .catch(function (err) {
                resolve({ ok: false, error: err })
            })
    })
}

function getDataActive(IdSucursal, IdComercio, IdUsuario, Token) {
    return new Promise(async function (resolve, reject) {
        var formBody = [
            encodeURIComponent("IdUsuario") +
                "=" +
                encodeURIComponent(IdUsuario) +
                "&" +
                encodeURIComponent("IdComercio") +
                "=" +
                encodeURIComponent(IdComercio) +
                "&" +
                encodeURIComponent("IdSucursal") +
                "=" +
                encodeURIComponent(IdSucursal)
        ]

        await fetch(backendUrl + "masterGetOrdersActive", {
            method: "POST",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded;charset=UTF-8",
                Authorization: "Bearer " + Token
            },
            body: formBody
        })
            .then((response) => {
                const statusCode = response.status
                const data = response.json()
                return Promise.all([statusCode, data]).then((res) => ({
                    statusCode: res[0],
                    data: res[1]
                }))
            })
            .then((res) => {
                const { statusCode, data } = res

                if (statusCode === 200) {
                    resolve({
                        ok: true,
                        data: data.data
                    })
                } else {
                    resolve({ ok: false, error: data.error })
                }
            })
            .catch(function (err) {
                resolve({ ok: false, error: err })
            })
    })
}

export function* handleTime() {
    yield takeEvery("HANDLE_TIME", function* ({ payload }) {
        const response = yield call(updateTime, payload)

        if (response.ok) {
            yield put({
                type: actions.TIME_SUCCESS,
                data: response.ok ? response.data : [],
                IdPedido: payload.IdPedido
            })
        } else {
            yield put({
                type: actions.TIME_ERROR,
                error: response.error
            })
        }
    })
}

function updateTime(payload) {
    return new Promise(async function (resolve, reject) {
        var formBody = [
            encodeURIComponent("IdPedido") +
                "=" +
                encodeURIComponent(payload.IdPedido) +
                "&" +
                encodeURIComponent("accion") +
                "=" +
                encodeURIComponent(payload.accion)
        ]

        await fetch(backendUrl + "updateTime", {
            method: "POST",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded;charset=UTF-8",
                Authorization: "Bearer " + payload.Token
            },
            body: formBody
        })
            .then((response) => {
                const statusCode = response.status
                const data = response.json()
                return Promise.all([statusCode, data]).then((res) => ({
                    statusCode: res[0],
                    data: res[1]
                }))
            })
            .then((res) => {
                const { statusCode, data } = res

                if (statusCode === 200) {
                    resolve({
                        ok: true,
                        data: data.tiempo
                    })
                } else {
                    resolve({ ok: false, error: data.error })
                }
            })
            .catch(function (err) {
                resolve({ ok: false, error: err })
            })
    })
}

export function* handleState() {
    yield takeEvery("HANDLE_STATE", function* ({ payload }) {
        const response = yield call(updateState, payload)

        if (response.ok) {
            yield put({
                type: actions.STATE_SUCCESS,
                IdPedido: payload.IdPedido
            })
        } else {
            yield put({
                type: actions.STATE_ERROR,
                error: response.error
            })
        }
    })
}

function updateState(payload) {
    return new Promise(async function (resolve, reject) {
        var formBody = [
            encodeURIComponent("IdPedido") +
                "=" +
                encodeURIComponent(payload.IdPedido) +
                "&" +
                encodeURIComponent("IdEstado") +
                "=" +
                encodeURIComponent(payload.IdEstado)
        ]

        await fetch(backendUrl + "updateOrderState", {
            method: "POST",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded;charset=UTF-8",
                Authorization: "Bearer " + payload.Token
            },
            body: formBody
        })
            .then((response) => {
                const statusCode = response.status
                const data = response.json()
                return Promise.all([statusCode, data]).then((res) => ({
                    statusCode: res[0],
                    data: res[1]
                }))
            })
            .then((res) => {
                const { statusCode, data } = res

                if (statusCode === 200) {
                    resolve({
                        ok: true
                    })
                } else {
                    resolve({ ok: false, error: data.error })
                }
            })
            .catch(function (err) {
                resolve({ ok: false, error: err })
            })
    })
}

export function* orderSuccess() {
    yield takeEvery(actions.ORDERS_SUCCESS, function* (payload) {})
}

export default function* rootSaga() {
    yield all([
        fork(getOrders),
        fork(getOrdersActive),
        fork(orderSuccess),
        fork(handleTime),
        fork(handleState)
    ])
}
