const orderActions = {
    GET_ORDERS: 'GET_ORDERS',
    GET_ORDERS_ACTIVE: 'GET_ORDERS_ACTIVE',
    ORDERS_SUCCESS: 'ORDERS_SUCCESS',
    ORDERS_ERROR: 'ORDERS_ERROR',
    HANDLE_TIME: 'HANDLE_TIME',
    TIME_SUCCESS: 'TIME_SUCCESS',
    TIME_ERROR: 'TIME_ERROR',
    HANDLE_STATE: 'HANDLE_STATE',
    STATE_SUCCESS: 'STATE_SUCCESS',
    STATE_ERROR: 'STATE_ERROR',
    getOrders: (IdSucursal, IdComercio, IdUsuario, Token) => ({
        type: orderActions.GET_ORDERS,
        payload: { IdSucursal, IdComercio, IdUsuario, Token },
    }),
    getOrdersActive: (IdSucursal, IdComercio, IdUsuario, Token) => ({
        type: orderActions.GET_ORDERS_ACTIVE,
        payload: { IdSucursal, IdComercio, IdUsuario, Token },
    }),
    handleTime: (IdPedido, accion, Token) => ({
        type: orderActions.HANDLE_TIME,
        payload: { accion,  IdPedido, Token},
    }),
    handleState: (IdPedido, IdEstado, Token) => ({
        type: orderActions.HANDLE_STATE,
        payload: { IdEstado,  IdPedido, Token},
    }),
};
export default orderActions;