import {
    SET_SOCKET_HELP_REQUESTS,
    SET_HELP_REQUESTS,
    ADD_HELP_REQUEST,
    SET_SELECTED_REQUEST,
    UNSET_SELECTED_REQUEST,
    OPEN_DIALOG_REQUEST_DETAILS,
    CLOSE_DIALOG_REQUEST_DETAILS,
    UPDATE_HELP_REQUEST,
    SHOW_FEEDBACK_HELP_REQUESTS,
    HIDE_FEEDBACK_HELP_REQUESTS
} from './types'


const INIT_STATE = {
    socket: null,
        feedBack: {
        show: false,
        message: '',
        severity: ''
    },
    openDialog: false,
    loadingRequests: true,
    requests: [],
    selectedRequest: null,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_SOCKET_HELP_REQUESTS:
            return {
                ...state,
                socket: action.payload
            }
        case SET_HELP_REQUESTS:
            return {
                ...state,
                loadingRequests: false,
                requests: action.payload
            }
        
        case ADD_HELP_REQUEST:
            state.requests.unshift(action.payload)
            return {
                ...state
            }
        
        case SET_SELECTED_REQUEST:
            return {
                ...state,
                selectedRequest: action.payload
            }
        
        case UNSET_SELECTED_REQUEST:
            return {
                ...state,
                selectedRequest: null
            }
        case OPEN_DIALOG_REQUEST_DETAILS:
            return {
                ...state,
                openDialog: true
            }
        case CLOSE_DIALOG_REQUEST_DETAILS:
            return {
                ...state,
                openDialog: false
            }
        case UPDATE_HELP_REQUEST:
            const requests = state.requests.map(r => (r.Id === action.payload.Id) ? action.payload : r)
            const selectedRequest = (state.selectedRequest && state.selectedRequest.Id === action.payload.Id) ? action.payload : state.selectedRequest
            if (!state.requests.some(r => r.Id === action.payload.Id)) {
                requests.unshift(action.payload)
            }
            return {
                ...state,
                requests: requests,
                selectedRequest: selectedRequest
            }
        case SHOW_FEEDBACK_HELP_REQUESTS:
            return {
                ...state,
                feedBack: {
                    show: true,
                    message: action.payload.message,
                    severity: action.payload.severity
                }
            }
        case HIDE_FEEDBACK_HELP_REQUESTS:
            return {
                ...state,
                feedBack: INIT_STATE.feedBack
            }
                default:
            return state
    }
}