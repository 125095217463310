export const PUBLIC_ROUTE = {
  LANDING: '/',
  // SIGN_IN: '/signin',
  // SIGN_UP: '/signup',
  // FORGET_PASSWORD: '/forgotpassword',
  // RESET_PASSWORD: '/resetpassword',
  PAGE_404: '/404',
  PAGE_500: '/500',
  PAGE_403: '/403',
  // AUTH0_CALLBACK: '/auth0loginCallback',
};
