export const formatea_Rut_all = (rut) => {

    var actual = rut.replace(/^0+/, "");
    if (actual !== '' && actual.length > 1) {
        var sinPuntos = actual.replace(/\./g, "");
        var actualLimpio = sinPuntos.replace(/-/g, "");
        var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
        var rutPuntos = "";
        var i = 0;
        var j = 1;
        for (i = inicio.length - 1; i >= 0; i--) {
            var letra = inicio.charAt(i);
            rutPuntos = letra + rutPuntos;
            if (j % 3 === 0 && j <= inicio.length - 1) {
                rutPuntos = "." + rutPuntos;
            }
            j++;
        }
        var dv = actualLimpio.substring(actualLimpio.length - 1);
        rutPuntos = rutPuntos + "-" + dv;
    }
    return rutPuntos;
}

const getCurrencyType = (type) => {
    switch (type.toUpperCase()) {
        case 'CLP':
            return 'es-CL'
        case 'USD':
            return 'en-US'
        case 'PEN':
            return 'es-CL'
        case 'COP': {
            return 'es-CO'
        }
        default:
            return type
    }
}

export const formatNumber = (num, currency = 'CLP') => {
    let formattedNumber = new Intl.NumberFormat(getCurrencyType(currency), { style: 'currency', currency, maximumFractionDigits: 0 }).format(num)
    return formattedNumber
}


export const validateRut = (rut) => {
    var Fn = {
        // Valida el rut con su cadena completa "XXXXXXXX-X"
        validaRut: function (rutCompleto) {
            if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
                return false;
            var tmp = rutCompleto.split('-');
            var digv = tmp[1];
            var rut = tmp[0];
            if (digv == 'K') digv = 'k';
            return (Fn.dv(rut) == digv);
        },
        dv: function (T) {
            var M = 0, S = 1;
            for (; T; T = Math.floor(T / 10))
                S = (S + T % 10 * (9 - M++ % 6)) % 11;
            return S ? S - 1 : 'k';
        }
    }
    return Fn.validaRut(rut)
}

export const randomColor = () => {
    // Generar tres valores hexadecimales aleatorios para R, G y B
    const r = Math.floor(Math.random() * 256).toString(16)
    const g = Math.floor(Math.random() * 256).toString(16)
    const b = Math.floor(Math.random() * 256).toString(16)

    // Asegurarse de que cada componente tenga exactamente 2 caracteres
    const color = "#" + fillWithZeros(r) + fillWithZeros(g) + fillWithZeros(b)

    return color
}

const fillWithZeros = (val) => {
    return val.length === 1 ? "0" + val : val
}

export const disabledColor = '#ACACAC'

// Funcion para capitalizar una cadena de texto (primera letra de cada palabra en mayuscula)
export const capitalizeConst = (str) => (str ? str.toLowerCase().replace(/_/g, ' ').replace(/(^|\s)\S/g, l => l.toUpperCase()) : "")
// Determinar si un objeto es vacio
export const isVoid = (obj) => [undefined, null].includes(obj) || (typeof obj === 'object' && Object.keys(obj).length === 0)