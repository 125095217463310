const actions = {
    ADD_DATA_COURIER: 'ADD_COURIER',
    ADD_LIST_COURIER: 'ADD_LIST_COURIER',
    CLEAN_DATA_COURIER: 'CLEAN_DATA_COURIER',

    addDataCourier: (data) => ({
        type: actions.ADD_DATA_COURIER,
        payload: { data },
    }),
    addListCourier: (data) => ({
        type: actions.ADD_LIST_COURIER,
        payload: { data },
    }),
    cleanDataCourier: (data) => ({
        type: actions.CLEAN_DATA_COURIER,
        payload: { data },
    }),
}

export default actions