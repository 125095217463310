import actions from './actions';


const initState = {
	message: '',
	AppKey: '',
	Token: '',
	auth: {
		nacionalidad: {}
	},
	payload: {
		nacionalidades: [],
		privilegiosPerfiles: [],
		usuarioPerfiles: [],

		nacionalidad: {},
		usuarioPerfil: {},
		privilegiosPerfil: {
			privilegios: []
		},
		centroNegocioPerfil: null
	}
};

export default function authReducer(state = initState, action) {
	switch (action.type) {
		case actions.LOGIN_SUCCESS_DATA:
			return {
				...state,
				...action.payload
			}
		case actions.LOGOUT_SUCCESS:
			return initState;
		default:
			return state;
	}
}
