// SETTERS
export const SET_ETIQUETAS = 'SET_ETIQUETAS'
export const SET_MENUS_DE_AYUDA = 'SET_MENUS_DE_AYUDA'
export const SET_FATHER_MENU_DE_AYUDA = 'SET_FATHER_MENU_DE_AYUDA'
// ADDERS
export const ADD_MENUS_DE_AYUDA = 'ADD_MENUS_DE_AYUDA'
export const ADD_MENU_DE_AYUDA_NAV_HISTORY = 'ADD_MENU_DE_AYUDA_NAV_HISTORY'
// SAVERS
export const SAVE_MENU_DE_AYUDA = 'SAVE_MENU_DE_AYUDA'
// POPPERS
export const POP_MENU_DE_AYUDA_NAV_HISTORY = 'POP_MENU_DE_AYUDA_NAV_HISTORY'
// CLEANERS
export const CLEAR_NAV_HISTORY = 'CLEAR_NAV_HISTORY'
// DELETES
export const DELETE_MENU_DE_AYUDA = 'DELETE_MENU_DE_AYUDA'

export const CHANGE_ACTIVE_MENU = 'CHANGE_ACTIVE_MENU'
